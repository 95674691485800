import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Button,
  Upload,
  Radio,
  message
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { API_URL } from '../../constants/general';
import Axios from 'axios';
import Loader from './loader/Loader';
import { IPlayerType } from '../../schemas/IPlayertypes.d';
import { IContact } from '../../schemas/IContact.d';
import LocationAutoComplete from './LocationAutoComplete';
import { ILocation } from '../../schemas/ILocation.d';


interface IProps {
  btnLoading: boolean;
  contact?: IContact;
  setPassportPhoto: any;
  setBirthCertificate: any;
  pLocation?: ILocation;
}

export default function RenewForm(props: IProps) {
  const { btnLoading, contact, setPassportPhoto, setBirthCertificate, pLocation } = props;
  const [playerTypes, setPlayerTypes] = useState<IPlayerType[]>([]);
  const [selectedPlayerTypes, setSelectedPlayerTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const IPassportPhotoProps = {
    name: 'image',
    action: `${API_URL}contacts/add-image`,
    headers: {
      authorization: 'authorization-text'
    },
    data: {
      type: "contact_passport_photo",
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const { response } = info?.file;
        const image = response?.image;
        if (image && image?.entity === "contact_passport_birth_certificate") {
          setBirthCertificate(image.id);
        }
        if (image && image?.entity === "contact_passport_photo") {
          setPassportPhoto(image.id);
        }
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        const { errors } = info?.file?.response;
        if (errors) {
          const { image } = errors;
          if (image) message.warning(image?.[0]);
        }
      }
    }
  };


  const IBirtCertificateProps = {
    name: 'image',
    action: `${API_URL}contacts/add-image`,
    headers: {
      authorization: 'authorization-text'
    },
    data: {
      type: "contact_passport_birth_certificate",
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        const { response } = info?.file;
        const image = response?.image;
        if (image && image?.entity === "contact_passport_birth_certificate") {
          setBirthCertificate(image.id);
        }
        if (image && image?.entity === "contact_passport_photo") {
          setPassportPhoto(image.id);
        }
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const { data } = await Axios.get(`types`);
    setPlayerTypes(data);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }

  const getAmount = (types: Array<string>) => {
    let amount = 0;
    types.map(type => {
      const ft = playerTypes.find(f => f.slug === type);
      if (ft) amount += +ft.amount;
    });
    return amount;
  }

  const handleChangePtype = (e: any) => {
    setSelectedPlayerTypes(e);
    console.log(getAmount(e))
  }

  useEffect(() => {
    getData();
  }, [])

  return isLoading ? <Loader /> :
    <>
      <Row gutter={[30, 20]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="first_name"
            label="First Name"
            initialValue={contact?.first_name}
            rules={[{ required: true, message: 'Please input your first name!' }]}
          >
            <Input placeholder="First name" readOnly={true} disabled={true} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item name="middle_name" label="Middle Name" initialValue={contact?.middle_name}>
            <Input placeholder="Middle name" readOnly={true} disabled={true} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Form.Item
            name="last_name"
            label="Last Name"
            initialValue={contact?.last_name}
            rules={[{ required: true, message: 'Please input your last name!' }]}
          >
            <Input placeholder="Last name" readOnly={true} disabled={true} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[30, 20]}>
      <Col span={12}>
          <Form.Item
            initialValue={contact?.email}
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter valid email' }
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            initialValue={contact?.fide_id}
            name="fide_id"
            label="FIDE ID"
          >
            <Input placeholder="FIDE ID" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="aicf_id"
            initialValue={contact?.aicf_id}
            label="AICF ID"
          >
            <Input type="text" placeholder="AICF ID"  readOnly={true} disabled={true}/>
          </Form.Item>
        </Col>
      </Row>


      <Row gutter={[30, 20]}>
        <LocationAutoComplete
          selectedState={(contact?.state && pLocation?.state_name) ? { key: `${contact.state}`, value: `${contact.state}`, label: pLocation.state_name } : undefined}
          selecteCity={(contact?.city && pLocation?.city_name) ? { key: `${contact.city}`, value: `${contact.city}`, label: pLocation.city_name } : undefined}
          selectedDistrict={(contact?.district && pLocation?.district_name) ? { key: `${contact.district}`, value: `${contact.district}`, label: pLocation.district_name } : undefined}
        />
      </Row>


      <Row gutter={[200, 20]} style={{ marginTop: 10, marginBottom: 20 }}>
        <Col>
          <Form.Item
            name="player_type"
            label="Player Type"
            rules={[{ required: true }]}>
            <Checkbox.Group onChange={handleChangePtype}>
              {playerTypes?.map(item =>
                <Checkbox value={item.slug} key={item.slug}>{item.name}</Checkbox>
              )}
            </Checkbox.Group>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="poi" label="Are you a PIO/OCI" initialValue={contact?.poi ? true : false}>
            <Radio.Group>
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>


      <Row style={{ marginTop: '30px' }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Upload {...IPassportPhotoProps} multiple={false}>
            <Button type="default" icon={<UploadOutlined />} >
              Passport Size Photo
            </Button>
          </Upload>

          <p style={{ marginTop: 20 }}>
            (jpg, png images only. Maximum size 1000 KB)
          </p>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Upload {...IBirtCertificateProps} multiple={false}>
            <Button type="default" icon={<UploadOutlined />}>
              Birth Certificate
            </Button>
          </Upload>
          <p style={{ marginTop: 20 }}>(PDF, JPEG, JPG, PNG documents only. Maximum size 1000 KB) (Optional)</p>
        </Col>
      </Row>

      <Row style={{ marginTop: '30px' }}>
        <Col>
          <Button type="primary" size="large" htmlType="submit" loading={btnLoading}>
            Submit Form
          </Button>
          {selectedPlayerTypes.length > 0 && <strong style={{ margin: '0 2rem' }}>* Pay INR {getAmount(selectedPlayerTypes)}</strong>}
        </Col>
      </Row>
    </>
}



